const state = {
    singleReports: [],
    goodTypes: []
}

const mutations = {
    SET_SINGLE_REPORTS: (state, singleReports) => {
        state.singleReports = singleReports
    },
    SET_GOOD_TYPES: (state, goodTypes) => {
        state.goodTypes = goodTypes
    }
}

const actions = {
    addArray({ commit }) {
        let new_array = state.singleReports;
        new_array.push({
            Code: "",
            GoodsName: "",

            GoodsTypeId: state.goodTypes.length ? state.goodTypes[0].Id : "",
            Account: 1,
            Price: 10,
            TotalPrice: 10,
            ClientRem: ''
        })
        commit('SET_SINGLE_REPORTS', new_array)
    },
    changeGoodTypes({ commit }, array) {
        commit('SET_GOOD_TYPES', array)
    },
    removeArray({ commit }) {
        let new_array = state.singleReports;
        new_array.pop();
        commit('SET_SINGLE_REPORTS', new_array)
    },
    resetTemp({ commit }) {
        commit('SET_SINGLE_REPORTS', [])
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
